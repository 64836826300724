/* eslint-disable indent */
import { useState } from "react";

import c from "classnames";
import Image from "next/image";

import { RadioProps } from "@/types/inputFields";
import { HelpCard } from "@/pagesComponents/Funnel/HelpCard";

import Styles from "./radio.module.scss";
import { RadioOption } from "./RadioOption";

import { Label } from "../Label";

export const Radio = ({
	label,
	options,
	name,
	register,
	validate,
	errors,
	toggle = false,
	value,
	className,
	narrow,
	small,
	onChange,
	labelType,
	wrapperClassname,
	skinny,
	noCircle = false,
	required = false,
}: RadioProps) => {
	const [activePosition, setActivePosition] = useState(0);

	const helpCardMoving = options.findIndex(
		(item) => `${value}` === `${item.id}`
	);

	const Content = (
		<>
			{label && (
				<Label
					htmlFor={name}
					label={label}
					labelType={labelType}
					skinny={skinny}
				/>
			)}

			<div
				className={c({
					...(!toggle
						? {
								container: true,
								containerSix: true,
								containerNoPad: true,
						  }
						: {}),
					[wrapperClassname || ""]: !!wrapperClassname,
					[Styles.toggleGroup]: toggle,
					[Styles.trio]: options?.length === 3,
				})}
			>
				{toggle && helpCardMoving > -1 && (
					<div
						className={c(Styles.activeIndicator, {
							[Styles.duo]: options?.length === 2,
							[Styles.trio]: options?.length === 3,
						})}
						style={{ left: `${activePosition}px` }}
					></div>
				)}
				{options.map((option, index) => (
					<RadioOption
						key={index}
						{...{
							onChange,
							narrow,
							small,
							amountOfOptions: options.length,
							option,
							isSelected: `${value}` === `${option.id}`,
							isToggle: toggle,
							hasError: !!errors?.[name],
							setActivePosition,
							required,
							noCircle: noCircle,
							...(register
								? {
										register: register(name, {
											...validate,
											required,
										}),
								  }
								: {}),
						}}
					/>
				))}
			</div>
			{options[0]?.helpText && helpCardMoving > -1 && (
				<HelpCard
					moving={helpCardMoving}
					movingMax={options.length}
					className={c(Styles.helpCard, {
						[Styles.helpCardSmall]: name === "treats",
					})}
				>
					<h4>
						{options.find((option) => `${value}` === `${option.id}`)?.label}
					</h4>
					<p>
						{options.find((option) => `${value}` === `${option.id}`)?.helpText}
					</p>
					{options.find((option) => `${value}` === `${option.id}`)
						?.helpImage && (
						// eslint-disable-next-line @next/next/no-img-element
						<div className={Styles.helpCardImage}>
							<Image
								alt={
									options.find((option) => `${value}` === `${option.id}`)
										?.helpText
								}
								layout="fill"
								priority
								src={
									"/images/" +
									options.find((option) => `${value}` === `${option.id}`)
										?.helpImage
								}
							/>
						</div>
					)}
				</HelpCard>
			)}
		</>
	);

	return className ? <div className={className}>{Content}</div> : Content;
};
